import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Oval } from 'react-loader-spinner';
import { FaPlus } from 'react-icons/fa';
import { MdError } from 'react-icons/md';
import { useQuery } from 'react-query';
import { AssetRecord } from './AssetRecord';
import { useNavigate } from 'react-router-dom';

import "./NewAsset.scss";

interface AddNewAssetModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const requiredFields = {
    applicationName: 'Application Name is required',
    canonicalId: 'Canonical ID is required',
    productFamilyName: 'Product Family Name is required',
    jiraUrl: 'Jira Destination is required'
} as const;

type RequiredField = keyof typeof requiredFields;

const fetchData = async (): Promise<AssetRecord> => {
    let response = await axios.get("/assets/template")
    return response.data
}

interface FormLabelProps {
    fieldName: string;
    isRequired?: boolean;
}

const FormLabel: React.FC<FormLabelProps> = ({ fieldName, isRequired }) => (
    <label className={isRequired ? 'required' : ''}>
        {fieldName}
        {isRequired && <span className="required-indicator">*</span>}
    </label>
);

const AddNewAssetModal: React.FC<AddNewAssetModalProps> = ({ isOpen, onClose }) => {
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [validationMessages, setValidationMessages] = useState<string[]>([]);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const {
        data
    } = useQuery("get-template", fetchData)
    
    useEffect(() => {
        if (isOpen) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }

        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [isOpen]);

    useEffect(() => {
        if (validationMessages.length > 0) {
            const timeoutId = setTimeout(() => setValidationMessages([]), 10000);
            return () => clearTimeout(timeoutId);
        }
    }, [validationMessages]);

    const validateFields = (): string[] => {
        if (!data) return [];
        
        return (Object.entries(requiredFields) as [RequiredField, string][])
            .filter(([field]) => !data[field])
            .map(([_, message]) => message);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsSubmitting(true);
        setValidationMessages([]);
        setSuccessMessage(null);

        try {
            if (!data) {
                throw new Error('Failed to add application. Please try again.');
            }

            const validationErrors = validateFields();
            if (validationErrors.length > 0) {
                setValidationMessages(validationErrors);
                return;
            }

            data.isDraft = true;
            const response = await axios.post('assets', data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    
            if (response.status === 200) {
                setSuccessMessage('New Asset Added.');
                setTimeout(() => setSuccessMessage(null), 5000);
                navigate(`/assets/${response.data.assetId}`);
            }
        } catch (error: any) {
            if (error.response?.status === 400 && error.response?.data?.validationFailures) {
                setValidationMessages(error.response.data.validationFailures);
            } else if (error instanceof Error) {
                setValidationMessages([error.message]);
            } else {
                setValidationMessages(['An unknown error occurred. Please try again.']);
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="add-application-modal-overlay">
            <div className="modal-content add-application-modal">
                <h3>
                    <FaPlus className="icon" />
                    Add New Asset
                </h3>
                <form onSubmit={handleSubmit}>
                    <div className="form-fields">
                        <div className="form-group">
                            <FormLabel fieldName="Application Name" isRequired={true} />
                            <textarea
                                onChange={(e) => {
                                    if(!data) {return}
                                    data.applicationName = e.target.value;
                                    data.assetName = e.target.value;
                                }}
                                disabled={isSubmitting}
                            />
                        </div>
                        <div className="form-group">
                            <FormLabel fieldName="Canonical ID" isRequired={true} />
                            <textarea
                                onChange={(e) => {
                                    if(!data) {return}
                                    data.canonicalId = e.target.value;
                                }}
                                disabled={isSubmitting}
                            />
                        </div>
                        <div className="form-group">
                            <FormLabel fieldName="Product Family Name" isRequired={true} />
                            <textarea
                                onChange={(e) => {
                                    if(!data) {return}
                                    data.productFamilyName = e.target.value;
                                }}
                                disabled={isSubmitting}
                            />
                        </div>
                        <div className="form-group">
                            <FormLabel fieldName="Jira Destination" isRequired={true} />
                            <textarea
                                onChange={(e) => {
                                    if(!data) {return}
                                    data.jiraUrl = e.target.value;
                                }}
                                disabled={isSubmitting}
                            />
                        </div>
                        <div className="form-group">
                            <FormLabel fieldName="Tags" isRequired={false} />
                            <textarea
                                onChange={(e) => {
                                    if(!data) {return}
                                    data.tags = e.target.value.split(',');
                                }}
                                disabled={isSubmitting}
                            />
                        </div>
                        {validationMessages.length > 0 && (
                            <div className="error-message">
                                {validationMessages.map((message, index) => (
                                    <div key={index}><MdError /> {message}</div>
                                ))}
                            </div>
                        )}
                        {successMessage && <div className="success-message">{successMessage}</div>}
                    </div>
                    <div className="button-group">
                        <button type="button" onClick={onClose} className="cancel-btn" disabled={isSubmitting}>
                            Close
                        </button>
                        <button type="submit" className="submit-btn" disabled={isSubmitting}>
                            {isSubmitting ? (
                                <>
                                    <Oval
                                        visible={true}
                                        height="16"
                                        width="16"
                                        color="#ffffff"
                                        secondaryColor="#ffffff"
                                        ariaLabel="loading"
                                    />
                                    Submitting...
                                </>
                            ) : (
                                <>
                                    Save as Draft
                                </>
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddNewAssetModal;