import React from 'react';
import "./Root.scss"
import { TopNav } from "./TopNav";
import { Main } from "./Main";

import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { loginRequest } from '../authConfig';

type RootProps = {
    instance: PublicClientApplication
}

const authRequest = {
    ...loginRequest,
};

const Root: React.FC<RootProps> = ({ instance }) => {
    return (
        <MsalProvider instance={instance}>
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authRequest}>
                <TopNav/>
                <Main />
            </MsalAuthenticationTemplate>
        </MsalProvider>
    )
}

export default Root