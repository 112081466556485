import { motion, Transition } from 'framer-motion';

const AnimatedShieldLoader = () => {
  const pathVariants = {
    initial: {
      pathLength: 0,
      opacity: 0.3
    },
    animate: {
      pathLength: 1,
      opacity: 1,
    }
  };

  const baseTransition: Transition = {
    duration: 1.0,
    repeat: Infinity,
    ease: "easeInOut",
    repeatType: "loop"
  };

  return (
    <div className="flex items-center justify-center">
      <motion.svg
        width="50"
        height="50"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <motion.path
          d="M12 3L5 6V12C5 16.14 8.04 20.02 12 21C15.96 20.02 19 16.14 19 12V6L12 3Z"
          stroke="#72DEFF"
          strokeWidth="1.0"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
          initial="initial"
          animate="animate"
          variants={pathVariants}
          transition={baseTransition}
        />
      </motion.svg>
    </div>
  );
};

export default AnimatedShieldLoader;