import React from "react";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import { BsGraphUp, BsJournalText } from "react-icons/bs";
import { RiLockPasswordLine, RiShieldFlashFill } from "react-icons/ri";
//import { IoExtensionPuzzleOutline } from "react-icons/io5";
import { FaShieldAlt } from "react-icons/fa";
import "./TopNav.scss";

const navItems = [
    { to: "/", icon: RiShieldFlashFill, text: "CyberHub", isLogo: true },
    { to: "/assets", icon: BsJournalText, text: "Asset Register" },
    //{ to: "/applications", icon: BsGrid, text: "ScanHub Apps" },
    //{ to: "/veracode/apps", icon: IoExtensionPuzzleOutline, text: "Integrations" },
    //{ to: "/security-metadata", icon: BsJournalText, text: "Asset Register" },
    { to: "/secrets-scanner", icon: RiLockPasswordLine, text: "Secrets Scanner" },
    { to: "/threat-modeling", icon: FaShieldAlt, text: "Threat Modeling" },
    { to: "/jira-statistic", icon: BsGraphUp, text: "Reporting" }
    
];

// More subtle animation variants
const container = {
    hidden: { opacity: 0, y: -5 },
    show: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 0.3,
            staggerChildren: 0.05,
            delayChildren: 0.1,
            ease: "easeOut"
        },
    },
};

const item = {
    hidden: { opacity: 0, y: -2 },
    show: { 
        opacity: 1, 
        y: 0,
        transition: {
            duration: 0.2,
            ease: "easeOut"
        }
    },
};

export const TopNav: React.FC = () => {
    return (
        <div className="header-container">
            <motion.nav
                initial="hidden"
                animate="show"
                variants={container}
                className="nav-wrapper"
            >
                <motion.ul className="nav-list">
                    {navItems.map((navItem, index) => (
                        <motion.li
                            key={navItem.to}
                            variants={item}
                            className={`nav-item ${index === 0 ? 'logo' : 'tab'}`}
                        >
                            <NavLink
                                to={navItem.to}
                                className={({ isActive }) =>
                                    `nav-link ${isActive ? 'active' : ''} ${
                                        navItem.isLogo ? 'logo-link' : ''
                                    }`
                                }
                            >
                                {({ isActive }) => (
                                    <>
                                        <navItem.icon
                                            className={`${
                                                navItem.isLogo ? 'logo-icon' : 'nav-icon'
                                            }`}
                                        />
                                        <span>{navItem.text}</span>
                                        {isActive && !navItem.isLogo && (
                                            <motion.div
                                                layoutId="activeTab"
                                                className="active-background"
                                                transition={{
                                                    type: "spring",
                                                    bounce: 0.15,
                                                    duration: 0.5
                                                }}
                                            />
                                        )}
                                    </>
                                )}
                            </NavLink>
                        </motion.li>
                    ))}
                </motion.ul>
            </motion.nav>
        </div>
    );
};