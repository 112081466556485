import React, { useState, useMemo, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    TableSortLabel,
    IconButton,
    TextField
} from '@mui/material';
import { useQuery, useQueryClient } from 'react-query';
import axios from 'axios';
import AnimatedShieldLoader from '../AnimatedShieldLoader';
import './ThreatModelingList.scss';
import { 
    FaEye, 
    FaUserAlt, 
    FaUsers, 
    FaExclamationCircle, 
    FaPlus, 
    FaThumbsUp, 
    FaThumbsDown, 
    FaMeh, 
    FaChartBar,
    FaTicketAlt,
    FaTrash
} from 'react-icons/fa';
import { 
    MdSecurity, 
    MdOutlineViewTimeline, 
    MdDateRange, 
    MdModeEdit, 
    MdSave, 
    MdEditOff
} from 'react-icons/md';
import { SiJira } from "react-icons/si";
import { LuBrainCog } from "react-icons/lu";
import { useLocation } from 'react-router-dom';
import Modal from './Modal';
import RegenerateFeedbackModal from './RegenerateFeedbackModal';
import AddThreatModelModal from './AddThreatModelModal';

interface ThreatModel {
    jira_issue_id: string;
    assignee: string;
    jira_release: string;
    jira_title: string;
    last_updated: string;
    priority: string;
    product_team: string;
    security_relevance_score: number;
    threat_model: string;
    feedback: 'Liked' | 'Disliked' | 'Neutral';
}

type Order = 'asc' | 'desc';

const getFeedbackIcon = (feedback: string) => {
    switch (feedback) {
        case "Liked":
            return <FaThumbsUp size={15} />;
        case "Disliked":
            return <FaThumbsDown size={15} />;
        case "Neutral":
            return <FaMeh size={15} />;
        default:
            return <FaMeh size={15} />;
    }
};

export const ThreatModelScanner: React.FC = () => {
    const [sortConfig, setSortConfig] = useState<{ field: keyof ThreatModel; direction: Order }>({
        field: 'jira_release',
        direction: 'asc'
    });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [selectedModel, setSelectedModel] = useState<ThreatModel | null>(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [editedThreatModel, setEditedThreatModel] = useState('');
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isRegenerateFeedbackModalOpen, setIsRegenerateFeedbackModalOpen] = useState(false);
    const location = useLocation();
    const queryClient = useQueryClient();

    const { data: threatModels, isLoading, isError, error } = useQuery('threatModels', async () => {
        const response = await axios.get('/threat-modeling');
        return response.data;
    });

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const issueIds = searchParams.get('issues');
        if (issueIds) {
            setSearchQuery(issueIds);
        }
    }, [location]);

    const handleSort = (field: keyof ThreatModel) => {
        setSortConfig(prev => ({
            field,
            direction: prev.field === field && prev.direction === 'asc' ? 'desc' : 'asc'
        }));
    };

    const filteredAndSortedData = useMemo(() => {
        if (!threatModels) return [];
        
        const searchTerms = searchQuery.toLowerCase().split(',').map(term => term.trim());
        
        return [...threatModels]
            .filter(model => 
                searchTerms.some(term => 
                    model.jira_issue_id.toLowerCase().includes(term) ||
                    model.product_team.toLowerCase().includes(term) ||
                    model.jira_release.toLowerCase().includes(term)
                ) || !searchQuery
            )
            .sort((a, b) => {
                const aValue = String(a[sortConfig.field]);
                const bValue = String(b[sortConfig.field]);
                return sortConfig.direction === 'asc'
                    ? aValue.localeCompare(bValue)
                    : bValue.localeCompare(aValue);
            });
    }, [threatModels, sortConfig, searchQuery]);

    const feedbackStats = useMemo(() => {
        if (!threatModels) return { liked: 0, disliked: 0, neutral: 0, total: 0 };
        const total = threatModels.length;
        const liked = threatModels.filter(model => model.feedback === 'Liked').length;
        const disliked = threatModels.filter(model => model.feedback === 'Disliked').length;
        const neutral = total - liked - disliked;
        return { liked, disliked, neutral, total };
    }, [threatModels]);

    const handleFeedbackClick = async (feedback: 'Liked' | 'Disliked' | 'Neutral') => {
        if (!selectedModel) return;
        const newFeedback = selectedModel.feedback === feedback ? 'Neutral' : feedback;
        try {
            const params = new URLSearchParams({
                columnName: 'feedback',
                newValue: newFeedback
            });
            await axios.put(`/threat-modeling/${selectedModel.jira_issue_id}?${params.toString()}`, null);
            setSelectedModel({
                ...selectedModel,
                feedback: newFeedback
            });
        } catch (error) {
            console.error("Error updating feedback:", error);
        }
    };

    const handleRegenerate = async (feedback: string) => {
        if (!selectedModel) return;
        try {
            const formattedContent = `${selectedModel.jira_issue_id},${feedback}`;
            const messagePayload = {
                message: JSON.stringify({
                    ANALYSIS_TYPE: 'regenerate',
                    CONTENT: formattedContent
                })
            };
            await axios.post('/threat-modeling/send-to-sns', messagePayload);
        } catch (error) {
            console.error('Error sending regeneration request:', error);
        }
    };

    const handleSaveEdit = async () => {
        if (!selectedModel) return;
        try {
            const params = new URLSearchParams({
                columnName: 'threat_model',
                newValue: editedThreatModel
            });
            await axios.put(
                `/threat-modeling/${selectedModel.jira_issue_id}?${params.toString()}`,
                null
            );
            setSelectedModel({
                ...selectedModel,
                threat_model: editedThreatModel
            });
            setIsEditing(false);
        } catch (error) {
            console.error("Error updating threat model:", error);
        }
    };

    const handleDelete = async (jiraIssueId: string, event: React.MouseEvent) => {
        event.stopPropagation();
        if (window.confirm('Are you sure you want to delete this threat model? This action cannot be undone.')) {
            try {
                await axios.delete(`/threat-modeling/${jiraIssueId}`);
                queryClient.invalidateQueries('threatModels'); // Refresh the data in UI post deletion
            } catch (error) {
                console.error('Error deleting threat model:', error);
                alert('Failed to delete threat model. Please try again.');
            }
        }
    };

    if (isLoading) {
        return (
            <div className="application-table-container">
                <div id="loader">
                    <AnimatedShieldLoader />
                </div>
            </div>
        );
    }
    if (isError) return <div>Error: {(error as Error).message}</div>;

    return (
        <div className="application-table-container">
            <div className="application-table-search">
                <div className="feedback-stats">
                    <span title="Liked"><FaThumbsUp /> {feedbackStats.liked}</span>
                    <span title="Disliked"><FaThumbsDown /> {feedbackStats.disliked}</span>
                    <span title="Neutral"><FaMeh /> {feedbackStats.neutral}</span>
                    <span title="Total"><FaChartBar /> {feedbackStats.total}</span>
                </div>
                <button onClick={() => setIsAddModalOpen(true)} className="add-threat-model-btn">
                    <FaPlus /> Custom Threat Model
                </button>
                <TextField
                    placeholder="Search by Issue, Release or Team"
                    variant="outlined"
                    size="small"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </div>

            <TableContainer component={Paper} className="application-table-paper">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    active={sortConfig.field === 'jira_release'}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort('jira_release')}
                                >
                                    Fix Version
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={sortConfig.field === 'jira_issue_id'}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort('jira_issue_id')}
                                >
                                    Jira Issue ID
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={sortConfig.field === 'jira_title'}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort('jira_title')}
                                >
                                    Jira Title
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={sortConfig.field === 'product_team'}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort('product_team')}
                                >
                                    Product Team
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={sortConfig.field === 'priority'}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort('priority')}
                                >
                                    Priority
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={sortConfig.field === 'last_updated'}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort('last_updated')}
                                >
                                    Last Updated
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={sortConfig.field === 'security_relevance_score'}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort('security_relevance_score')}
                                >
                                    Score
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={sortConfig.field === 'feedback'}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort('feedback')}
                                >
                                    Feedback
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredAndSortedData
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((model) => (
                                <TableRow key={model.jira_issue_id} hover>
                                    <TableCell>{model.jira_release}</TableCell>
                                    <TableCell>
                                        <a 
                                            href={`https://dayforce.atlassian.net/browse/${model.jira_issue_id}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="jira-link"
                                        >
                                            {model.jira_issue_id}
                                            <SiJira className="jira-icon" />
                                        </a>
                                    </TableCell>
                                    <TableCell>
                                        <div className={`jira-title-cell ${model.jira_title.length > 50 ? 'scrollable' : ''}`}>
                                            {model.jira_title}
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div className={`jira-title-cell ${model.product_team.length > 40 ? 'scrollable' : ''}`}>
                                            {model.product_team}
                                        </div>
                                    </TableCell>
                                    <TableCell>{model.priority}</TableCell>
                                    <TableCell>{model.last_updated.split('T')[0]}</TableCell>
                                    <TableCell>{model.security_relevance_score}</TableCell>
                                    <TableCell>
                                        <div className={`feedback-status ${model.feedback}`}>
                                            {getFeedbackIcon(model.feedback)}
                                            {model.feedback}
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div className="action-buttons">
                                            <IconButton
                                                size="small"
                                                onClick={() => setSelectedModel(model)}
                                                className="view-model-btn"
                                                title="View Details"
                                            >
                                                <FaEye />
                                            </IconButton>
                                            <IconButton
                                                size="small"
                                                onClick={(e) => handleDelete(model.jira_issue_id, e)}
                                                className="delete-model-btn"
                                                title="Delete Threat Model"
                                            >
                                                <FaTrash />
                                            </IconButton>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                component="div"
                count={filteredAndSortedData.length}
                page={page}
                onPageChange={(_, newPage) => setPage(newPage)}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={(event) => {
                    setRowsPerPage(parseInt(event.target.value, 10));
                    setPage(0);
                }}
                className="application-table-pagination"
            />

            <Modal
                isOpen={!!selectedModel}
                onClose={() => {
                    setSelectedModel(null);
                    setIsEditing(false);
                }}
            >
                {selectedModel && (
                    <div className="threat-model-details">
                        <h3>
                            <FaTicketAlt className="icon" />
                            {selectedModel.jira_title}
                        </h3>
                        <p><SiJira className="icon" /> <strong>Jira Issue ID:</strong> {selectedModel.jira_issue_id}</p>
                        <p><MdOutlineViewTimeline className="icon" /> <strong>Jira Release:</strong> {selectedModel.jira_release}</p>
                        <p><FaUsers className="icon" /> <strong>Product Team:</strong> {selectedModel.product_team}</p>
                        <p><FaExclamationCircle className="icon" /> <strong>Priority:</strong> {selectedModel.priority}</p>
                        <p><FaUserAlt className="icon" /> <strong>Assignee:</strong> {selectedModel.assignee}</p>
                        <p><MdSecurity className="icon" /> <strong>Security Relevance Score:</strong> {selectedModel.security_relevance_score}</p>
                        <p><MdDateRange className="icon" /> <strong>Last Updated:</strong> { selectedModel.last_updated.split('T')[0]}</p>
                        <div className="threat-model-content">
                            <h4>
                                Threat Model:
                                <div className="button-group">
                                    <button
                                        onClick={() => handleFeedbackClick('Liked')}
                                        className={`feedback-btn ${selectedModel.feedback === 'Liked' ? 'active liked' : ''}`}
                                        title={selectedModel.feedback === 'Liked' ? 'Remove like' : 'Like'}
                                    >
                                        <FaThumbsUp /> Like
                                    </button>
                                    <button
                                        onClick={() => handleFeedbackClick('Disliked')}
                                        className={`feedback-btn ${selectedModel.feedback === 'Disliked' ? 'active disliked' : ''}`}
                                        title={selectedModel.feedback === 'Disliked' ? 'Remove dislike' : 'Dislike'}
                                    >
                                        <FaThumbsDown /> Dislike
                                    </button>
                                    {!isEditing ? (
                                        <>
                                            <button onClick={() => setIsEditing(true)} className="edit-btn">
                                                <MdModeEdit /> Edit
                                            </button>
                                            <button 
                                                onClick={() => setIsRegenerateFeedbackModalOpen(true)} 
                                                className="regenerate-btn"
                                            >
                                                <LuBrainCog /> Regenerate
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <button 
                                                onClick={() => {
                                                    setIsEditing(false);
                                                    setEditedThreatModel(selectedModel.threat_model);
                                                }} 
                                                className="cancel-btn"
                                            >
                                                <MdEditOff /> Cancel
                                            </button>
                                            <button 
                                                onClick={handleSaveEdit}
                                                className="save-btn"
                                            >
                                                <MdSave /> Save
                                            </button>
                                        </>
                                    )}
                                </div>
                            </h4>
                            {isEditing ? (
                                <textarea
                                    value={editedThreatModel || selectedModel.threat_model}
                                    onChange={(e) => setEditedThreatModel(e.target.value)}
                                    className="threat-model-textarea"
                                />
                            ) : (
                                <pre>{selectedModel.threat_model}</pre>
                            )}
                        </div>
                    </div>
                )}
            </Modal>

            <AddThreatModelModal
                isOpen={isAddModalOpen}
                onClose={() => setIsAddModalOpen(false)}
            />

            <RegenerateFeedbackModal
                isOpen={isRegenerateFeedbackModalOpen}
                onClose={() => setIsRegenerateFeedbackModalOpen(false)}
                onRegenerate={handleRegenerate}
            />
        </div>
    );
};

export default ThreatModelScanner;