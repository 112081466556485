import React, { useEffect, useState } from 'react';
import VulnerabilityRemediationExpandedChart from './VulnerabilityRemediationExpandedChart';
import './JiraStatistic.scss';
import axios from 'axios';
import RatioOfIntroducedResolvedChart from './RatioOfIntroducedResolvedChart';
import ReductionGoalChart from './ReductionGoalChart';
import IntroducedTicketsChart from './IntroducedTicketsChart';
import RemediateTimeChart from './RemediateTimeChart';
import CsvExport from './CsvExport';

interface JiraStatisticProps {
    // Add any props you need for the component here
}

const JiraStatistic: React.FC<JiraStatisticProps> = (/*{ /* Destructure props here  }*/) => {
    const [applications, setApplications] = useState([]);
    const [productFamilies, setProductFamilies] = useState([]);

    useEffect(() => {
        axios.get('/SecurityMetadata/app-and-families').then(response => {
            setApplications(response.data.applicationNames);
            setProductFamilies(response.data.families);
        })
    }, []);

    return (
        <div>
            <form>
            <VulnerabilityRemediationExpandedChart applications={applications} productFamilies={productFamilies} />
            </form>
            <br></br>
            <form>
            <RatioOfIntroducedResolvedChart  applications={applications} productFamilies={productFamilies} />
            </form>
            <br></br>
            <form>
            <ReductionGoalChart  applications={applications} productFamilies={productFamilies} />
            </form>
            <br></br>
            <form>
            <IntroducedTicketsChart  applications={applications} productFamilies={productFamilies} />
            </form>
            <br></br>
            <form>
            <RemediateTimeChart  applications={applications} productFamilies={productFamilies} />
            </form>
            <br></br>
            <form>
            <CsvExport applications={applications} productFamilies={productFamilies} />
            </form>
            <br></br>
        </div>
    );
};

export default JiraStatistic;